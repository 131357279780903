import React from 'react';
import { Button, Typography, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

const Hero = () => {
  const { t } = useTranslation();

  const heroStyle = {
    backgroundColor: '#000000',
    color: 'white',
    height: '19.1vh',
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
  };

  const leftSideStyle = {
    textAlign: 'left',
    paddingLeft: '50px',
    margin: '10px 0 0 0',
  };

  const rightSideStyle = {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: '100px',
  };

  const buttonGroupStyle = {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    margin: '0 0 13px 0',
  };

  return (
    <div style={heroStyle}>
      <Grid container>
        <Grid item xs={12} sm={6} style={leftSideStyle}>
          <Typography variant="h4" gutterBottom>
            CT Developers Solutions
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={rightSideStyle}>
          <Box style={buttonGroupStyle}>
            <LanguageSwitcher />
            {/*<Button 
              variant="contained" 
              size="large" 
              sx={{ backgroundColor: '#ffffff', color: '#000000', '&:hover': { backgroundColor: '#e0e0e0' },     height: '37px',  
              minWidth: '144px' }}
            >
              {t('knowMore')}
            </Button>*/}
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Hero;
