import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button 
          variant="contained" 
          onClick={() => changeLanguage('en')}
          sx={{ backgroundColor: '#ffffff', color: '#000000' }}
        >
          English
        </Button>
      </Grid>
      <Grid item>
        <Button 
          variant="contained" 
          onClick={() => changeLanguage('pt')}
          sx={{ backgroundColor: '#ffffff', color: '#000000' }}
        >
          Português
        </Button>
      </Grid>
    </Grid>
  );
};

export default LanguageSwitcher;
