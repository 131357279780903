import React from 'react';
import { Typography, Container, Grid, Card, CardContent, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Carousel from 'react-material-ui-carousel';

const allProjects = [
  { title: 'phdResearchDataTitle', description: 'phdResearchDataDescription', link: 'https://data-repository.ctdevelopers.solutions/nenem-ufscar/' },
  { title: 'personalFinanceWebAppTitle', description: 'personalFinanceWebAppDescription', link: 'https://money-couple.com/login' },
];

const Portfolio = () => {
  const { t } = useTranslation();

  const headlineCardStyle = {
    backgroundColor: '#000',
    color: '#fff',
    padding: '20px',
    marginBottom: '40px',
    textAlign: 'center',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
    width: '100%',
  };

  const cardStyle = {
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '20px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f5f5f5',
  };

  const cardContentStyle = {
    marginBottom: '10px',
  };

  return (
    <Container maxWidth="lg" style={{ padding: '50px 0' }}>
      
      {/* Headline in a card */}
      <Card style={headlineCardStyle}>
        <Typography variant="h4" gutterBottom>
          {t('portfolioHeading')}  {/* Translate Portfolio Heading */}
        </Typography>
      </Card>

      {/* Material-UI Carousel */}
      <Carousel
        animation="slide"
        indicators={false}
        navButtonsAlwaysVisible={true}
        autoPlay={false}
        navButtonsProps={{
          style: {
            backgroundColor: 'black',
            color: 'white',
            margin: '0 20px',
            borderRadius: 0,
          }
        }}
      >
        {allProjects.map((project, index) => (
          <Grid container justifyContent="center" alignItems="center" key={index} spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Card style={cardStyle}>  {/* Apply cardStyle to ensure consistent height */}
                <CardContent style={cardContentStyle}>
                  <Typography variant="h6">
                    {t(project.title)}  {/* Translate Project Title */}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t(project.description)}  {/* Translate Project Description */}
                  </Typography>
                </CardContent>
                <Button
                  size="small"
                  href={project.link}
                  target="_blank"
                  rel="noopener"
                  sx={{ marginTop: 'auto', backgroundColor: '#000', color: '#fff' }}
                >
                  {t('viewProject')}
                </Button>
              </Card>
            </Grid>
          </Grid>
        ))}
      </Carousel>
    </Container>
  );
};

export default Portfolio;
