import React from 'react';
import About from './About';
import Services from './Services';
import Portfolio from './Portfolio';  // Import Portfolio component

const AboutServices = () => {
  const sectionStyle = {
    backgroundImage: 'url("/images/circuitos.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '50px 0',
    width: '100vw',
    margin: 0,
  };

  return (
    <div style={sectionStyle}>
      {/* About Section */}
      <About />
      <br />
      
      {/* Services Section */}
      <Services />
      <br />
      
      {/* Portfolio Section */}
      <Portfolio />
    </div>
  );
};

export default AboutServices;
