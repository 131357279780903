import React, { useState } from 'react';
import { Button, Container, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';  // Import the translation hook

const CTA = () => {
  const { t } = useTranslation();  // Initialize the translation hook
  const [showEmail, setShowEmail] = useState(false);  // Track whether to show the email

  const ctaStyle = {
    backgroundColor: '#000000',
    color: 'white',
    minHeight: '20vh',
    display: 'flex',
    alignItems: 'center',
    width: '100vw',
  };

  const handleContactClick = () => {
    setShowEmail(true);
  };

  return (
    <div style={ctaStyle}>
      <Container maxWidth="md" style={{ textAlign: 'center', padding: '50px 0' }}>
        <Typography variant="h4" gutterBottom>
          {t('ctaHeading')}  {/* Translated heading */}
        </Typography>

        {/* Show email if the button is clicked, otherwise show the button */}
        {showEmail ? (
          <Typography variant="h6" style={{ color: 'white' }}>
            wcezar@ctdevelopers.solutions
          </Typography>
        ) : (
          <Button 
            variant="contained" 
            onClick={handleContactClick}  // Show the email when clicked
            sx={{ backgroundColor: '#ffffff', color: '#000000' }}
          >
            {t('contactUs')}  {/* Translated button text */}
          </Button>
        )}
      </Container>
    </div>
  );
}

export default CTA;
