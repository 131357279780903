import React from 'react';
import { Grid, Typography, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';

const services = [
  { key: 'customSoftwareDevelopment' },
  { key: 'cloudSolutions' },
  { key: 'webDevelopment' },
  { key: 'devOpsAutomation' },
  { key: 'webSites' }
];

const Services = () => {
  const { t } = useTranslation();

  const servicesStyle = {
    backgroundColor: 'rgba(224, 224, 224, 0.8)',
    padding: '50px 0',
    textAlign: 'center',
    width: '100%',
    margin: '50px 0 0 0',
    boxSizing: 'border-box',
  };

  const gridStyle = {
    maxWidth: '1200px',
    margin: '0 auto',
  };

  const headingStyle = {
    fontWeight: 'bold',
    fontSize: '2.5rem',
    letterSpacing: '0.1rem',
    marginBottom: '20px',
  };

  const serviceCardStyle = {
    padding: '20px',
    textAlign: 'center',
    backgroundColor: '#fff',
    height: '50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };

  return (
    <div style={servicesStyle}>
      <div style={gridStyle}>
        <Typography variant="h4" style={headingStyle} gutterBottom>
          {t('ourServices')}
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {services.map((service, index) => (
            <Grid 
              item 
              key={index} 
              xs={12}  // Full width on extra-small screens (mobile)
              sm={6}   // Half-width on small screens (tablet)
              md={4}   // One-third width on medium screens (small desktop)
              lg={3}   // One-quarter width on large screens (desktop)
            >
              <Paper elevation={3} style={serviceCardStyle}>
                <Typography variant="h6">{t(service.key)}</Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Services;
