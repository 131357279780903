import React from 'react';
import Hero from './components/Hero';
import CTA from './components/CTA';
import Footer from './components/Footer';
import AboutServices from './components/AboutServices';
import './i18n';

function App() {
  return (
    <div className="App">
      <Hero />
      <AboutServices />
      <CTA />
      <Footer />
    </div>
  );
}

export default App;
