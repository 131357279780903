import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  const aboutStyle = {
    backgroundColor: 'rgba(224, 224, 224, 0.8)',
    padding: '50px 0',
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '50px 0 0 0',
    boxSizing: 'border-box',
  };

  const contentStyle = {
    maxWidth: '960px',
    margin: '0 auto',
  };

  const headingStyle = {
    fontWeight: 'bold',
    fontSize: '2.5rem',
    letterSpacing: '0.1rem',
  };

  const bodyTextStyle = {
    fontSize: '1.2rem',
    fontWeight: '500',
  };

  return (
    <div style={aboutStyle}>
      <div style={contentStyle}>
        <Typography variant="h4" gutterBottom style={headingStyle}>
          {t('aboutUsHeading')}  {/* Translated heading */}
        </Typography>
        <Typography variant="body1" paragraph style={bodyTextStyle}>
          {t('aboutUsText')}  {/* Translated body text */}
        </Typography>
      </div>
    </div>
  );
};

export default About;
