import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';  // Responsible for loading translation files
import LanguageDetector from 'i18next-browser-languagedetector';  // Detects the user's language

// Initialize i18n
i18n
  .use(Backend)  // To load translation files from the backend (public/locales folder)
  .use(LanguageDetector)  // To detect the user's language
  .use(initReactI18next)  // Pass i18n instance to react-i18next
  .init({
    fallbackLng: 'en',  // Fallback language if no match is found
    debug: true,  // Enable for debugging language detection and translations
    interpolation: {
      escapeValue: false,  // React already handles escaping
    },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',  // Path to your translation files
    },
  });

export default i18n;
