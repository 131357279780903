import React from 'react';
import { Container, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md" style={{ textAlign: 'center', padding: '20px 0' }}>
      <Typography variant="body2" color="textSecondary">
        {t('followUs')}  {/* Translated text */}
      </Typography>
      <Typography variant="body2">
        <Link href="https://github.com/ctdevelopers" color="inherit">GitHub</Link> | 
        <Link href="https://twitter.com/ctdevelopers" color="inherit">Twitter</Link>
      </Typography>
      <Typography variant="body2" color="textSecondary" style={{ marginTop: '10px' }}>
        &copy; 2024 {t('rightsReserved')}  {/* Translated copyright text */}
      </Typography>
    </Container>
  );
}

export default Footer;
